// Theming Bootstrap - https://getbootstrap.com/docs/4.3/getting-started/theming/#sass
@import "./colors.scss";
@import "./grid-breakpoints.scss";
// @import "bootstrap-variables.scss";

$theme-colors: (
  "primary": $brand-red,
  "secondary": $dark-grey,
  "dark": $text-dark,
  "success": $link-green,
  "info": $blue,
);
$link-color: $link-green;
// Components
//
// Define common padding and border radius sizes and more.
// $line-height-lg:              1.5 !default;
// $line-height-sm:              1.5 !default;
// $border-width:                1px !default;
// $border-color:                $gray-300 !default;
$h1-font-size: 76px;
$border-radius: 5px;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;
// Navbar
$navbar-padding-y: 25px;
$navbar-padding-x: 25px;
$navbar-light-color: rgba($white, 1) !default;
$navbar-light-hover-color: rgba($border-grey, 1) !default;
$navbar-light-active-color: rgba($white, 0.9) !default;
// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-color: $text-dark;
// $input-btn-padding-y:         .375rem !default;
// $input-btn-padding-x:         .75rem !default;
// $input-btn-font-family:       null !default;
// $input-btn-font-size:         $font-size-base !default;
// $input-btn-line-height:       $line-height-base !default;
// $input-btn-focus-width:       .2rem !default;
// $input-btn-focus-color:       rgba($component-active-bg, .25) !default;
// $input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;
// $input-btn-padding-y-sm:      .25rem !default;
// $input-btn-padding-x-sm:      .5rem !default;
// $input-btn-font-size-sm:      $font-size-sm !default;
// $input-btn-line-height-sm:    $line-height-sm !default;
// $input-btn-padding-y-lg:      .5rem !default;
// $input-btn-padding-x-lg:      1rem !default;
// $input-btn-font-size-lg:      $font-size-lg !default;
// $input-btn-line-height-lg:    $line-height-lg !default;
// $input-btn-border-width:      $border-width !default;
// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
// $btn-padding-y: 14px;
// $btn-padding-x:               $input-btn-padding-x !default;
// $btn-font-family:             $input-btn-font-family !default;
// $btn-font-size:18px;
// $btn-line-height: 23px;
// $btn-padding-y-sm:            $input-btn-padding-y-sm !default;
// $btn-padding-x-sm:            $input-btn-padding-x-sm !default;
// $btn-font-size-sm:            $input-btn-font-size-sm !default;
// $btn-line-height-sm:          $input-btn-line-height-sm !default;
$btn-padding-y-lg: 21px;
$btn-padding-x-lg: 29px;
$btn-font-size-lg: 14px;
// $btn-line-height-lg:          $input-btn-line-height-lg !default;
// $btn-border-width:            $input-btn-border-width !default;
// $btn-font-weight:             $font-weight-normal !default;
// $btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
// $btn-focus-width:             $input-btn-focus-width !default;
// $btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
// $btn-disabled-opacity:        .65 !default;
// $btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;
// $btn-link-disabled-color:     $gray-600 !default;
// $btn-block-spacing-y:         .5rem !default;
// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: 5px;
// $btn-border-radius-sm:        $border-radius-sm !default;
// $btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
// Dropdowns
//
$dropdown-min-width: 163px;
$dropdown-font-size: 14px;
$dropdown-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.06);
$dropdown-border-color: #b6bcc2;
$dropdown-border-radius: $border-radius !default;
$dropdown-item-padding-x: 14px;
$dropdown-item-padding-y: 8px !default;
$caret-vertical-align: 2px;
$dropdown-link-hover-bg: #f4f4f4;
$dropdown-link-active-bg: #f4f4f4;
$dropdown-link-active-color: $black;
$enable-caret: false;
// Jumbotron
$jumbotron-padding: 4rem !default;

@import "~bootstrap/scss/bootstrap";

// class overides now
.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
@include media-breakpoint-down(sm) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.small-container {
  max-width: 663px;
  margin: auto;
}

@include media-breakpoint-up(xl) {
  .medium-container {
    max-width: 1100px;
    margin: auto;
  }
}

.rubbl-card {
  .card-header {
    font-size: 14px;
    font-weight: 300;
  }
}

.badge-wide {
  border-radius: 20px;
  padding: 0.35rem 0.85rem;
}

ngb-tooltip-window.bs-tooltip-top {
  .arrow::before {
    border-width: 0.8rem 0.8rem 0 !important;
    margin-left: -0.4rem !important;
    border-top-color: rgba(35, 37, 37, 1) !important;
  }
}
ngb-tooltip-window.bs-tooltip-left {
  left: -7px !important;
  .arrow::before {
    border-left-color: rgba(35, 37, 37, 1) !important;
    border-width: 0.8rem 0 0.8rem 0.8rem;
  }
}
ngb-tooltip-window.bs-tooltip-right {
  .arrow::before {
  }
}

.tooltip-inner {
}

.tooltip .arrow {
  height: 0.5rem;
}

.custom-tooltip {
  top: -8px !important;

  &.bs-tooltip-right,
  &.bs-tooltip-left {
    .tooltip-inner .icon-close {
      top: 4px;
    }
  }

  .tooltip-inner {
    text-align: left;
    min-width: 361px;
    padding: 0.75rem 2.2rem 0.75rem 1.2rem;
    font-weight: 300;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
    background-color: rgba(35, 37, 37, 1);

    .icon-close {
      position: absolute;
      top: 15px;
      right: 10px;
      font-size: 16px;
      padding: 3px;
    }
  }
}

.carousel-indicators li {
  background-color: $ui-green;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
