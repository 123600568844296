.btn-mobile {
  padding-top: 14px;
  padding-bottom: 14px;
}

.btn-mobile-lg {
  height: 48px;
}

.btn-mobile-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-outline-gray {
  border: 1px solid #b6bcc2;
  color: #232525;
  font-weight: 300;
}

.btn-outline-success {
  border: 1px solid $ui-green;
  color: $ui-green;
}

.btn-link {
  color: $link-green;
}

.btn-transparent-white {
  border: 2px solid #fff;
  color: #fff;
  background: transparent;

  &:hover {
    color: #fff;
  }
}

.btn-search {
  border-radius: 25px;
  font-weight: bold;
  letter-spacing: -0.3px;
}

button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.btn-no-box-shadow:focus {
  box-shadow: none !important;
}

.btn-lg {
  font-size: 1.125rem !important;
  min-width: 250px;

  &.fixed-height {
    height: 70px;
    padding: 0;
  }
}
.btn-white {
  background: #ffffff !important;
}

.btn:disabled {
  opacity: 0.3;
}

.btn .btn-outline-gray:disabled {
  border: 1px solid $light-grey;
  background-color: #f6f7f7;
}

.btn-modal-footer {
  height: 60px;
  min-width: 200px;
  font-size: 1.125rem !important;
  font-weight: bold;
  display: flex;
  align-items: center !important;
  justify-content: center !important;

  i {
    font-size: 1.625rem;
  }
}

@include media-breakpoint-down(sm) {
  .btn-modal-footer {
    height: 52px;
    min-width: 130px;
  }
}

.btn-edit {
  border: 1px solid #0aab9c;
  border-radius: 5px;
  color: #0aab9c;
  background: white;
}
