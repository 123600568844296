.toast-top-right,
.toast-top-center {
  top: 98px;
}

.toast-top-right {
  right: 31px;
}

@media (max-width: 575.98px) {
  .toast-top-right {
    right: 0;
    width: 100%;

    .ngx-toastr {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.toast-success {
  background-color: #0AAB9C;
}

.toast-message {
  color: #ffffff;
}
