@import "environment";
/* purgecss start ignore */
.modal,
.modal-backdrop.show {
  z-index: 4004 !important;
}

.fade {
  transition: none;
}

.custom-modal {
  display: block;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 3013;
  top: 0;
  left: 0;
  position: fixed;
  overflow-y: scroll;
}

.close-button-container {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 5px;
}

.modal-header .icon-close {
  color: #96a2a7;
}

.custom-modal-header {
  margin-top: 64px;
  padding-left: 15px;
}

.custom-modal-body {
  padding: 1.5rem 1rem 4rem 1rem;
}

.custom-modal-footer {
  position: fixed;
  bottom: 0;
  padding: 10px 35px;
  background-color: #ffffff;
}

// right-aside-modal start
.right-aside-modal {
  animation-name: slideInFromRight;
  animation-duration: 0.3s;

  .modal-dialog {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    width: 40%;
  }

  .modal-content {
    height: initial;
    min-height: 100%;
    border: none;
    border-radius: unset;
  }
}

.right-aside-modal-leave {
  animation-name: slideOutToRight;
  animation-duration: 0.3s;
}

@include media-breakpoint-down(md) {
  .right-aside-modal {
    .modal-dialog {
      width: 50%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .right-aside-modal {
    .modal-dialog {
      width: 100%;
    }
  }
}

@keyframes slideInFromRight {
  0% {
    //might go with 100%
    transform: translateX(400px);
  }

  100% {
    //might go with 0%
    transform: translateX(0px);
  }
}

@keyframes slideOutToRight {
  0% {
    //might go with 100%
    transform: translateX(0);
  }

  100% {
    //might go with 0%
    transform: translateX(500px);
  }
}

// gallery-modal start
.gallery-modal {
  .modal-dialog {
    max-width: 80vw;
  }

  ngb-carousel {
    margin: 0 auto;
  }

  ngb-carousel:focus {
    outline: 0;
  }

  .carousel-item img {
    max-width: 1200px;
    width: 100%;
  }

  // modal-content start
  .modal-content {
    background-color: unset;
    color: #fff;
    border: none;

    p {
      margin-top: 1em;
      font-size: 18px;
      color: #fff;
    }

    a.carousel-control-prev {
      left: -10%;
    }

    a.carousel-control-next {
      right: -10%;
    }

    a.carousel-control-prev,
    a.carousel-control-next {
      margin-top: 30%;
      background-color: rgba(0, 0, 0, 0.85);
      font-size: 1.5em;
      opacity: 1;
      width: 70px;
      height: 70px;
      border-radius: 2em;
      cursor: pointer;
    }
  }

  // modal-content end

  .carousel-control-next span.carousel-control-next-icon {
    background-image: url($baseImagePath + "/pubweb/marketplace/images/icons/right-arrow.svg");
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
  }

  .carousel-control-prev span.carousel-control-prev-icon {
    background-image: url($baseImagePath + "/pubweb/marketplace/images/icons/left-arrow.svg");
  }

  .close-button i.icon-close {
    color: #fff;
    font-size: 30px;
  }
}

@include media-breakpoint-down(sm) {
  .photo-gallery .carousel-control-prev,
  .photo-gallery .carousel-control-next,
  .gallery-modal .carousel-control-next,
  .gallery-modal .carousel-control-prev {
    display: none;
  }

  .gallery-modal .carousel-item img {
    width: 95vw;
  }

  .gallery-modal .modal-dialog {
    max-width: 95%;
  }
}

@include media-breakpoint-down(md) {
  .gallery-modal .modal-content {
    a.carousel-control-next,
    a.carousel-control-prev {
      width: 50px;
      height: 50px;
    }
  }
}

// gallery-modal end

.dark-backdrop.show {
  opacity: 1;
  background-color: #232525;
}

.modal-small .modal-dialog {
  width: 390px;
  margin-top: 5rem;
  animation-name: scalein;
  animation-duration: 0.3s;
}

@keyframes scalein {
  0% {
    transform: scale(0.3);
  }

  100% {
    transform: scale(1);
  }
}

// default modal with no custom modal class
.modal-header .icon-close {
  margin-top: 5px;
}

.modal-dialog {
  margin-top: 111px;
}

.modal.default-modal {
  animation-name: slideInFromBottom;
  animation-duration: 0.35s;

  .modal-dialog {
    max-width: 556px;
  }
}

.modal.default-modal-leave {
  animation-name: slideOutToBottom;
  animation-duration: 0.35s;
}

.modal.large-modal {
  animation-name: slideInFromBottom;
  animation-duration: 0.35s;

  .modal-dialog {
    max-width: 700px;
  }
}

.modal.large-modal-leave {
  animation-name: slideOutToBottom;
  animation-duration: 0.35s;
}

.modal.xl-modal {
  animation-name: slideInFromBottom;
  animation-duration: 0.35s;
}

@include media-breakpoint-down(lg) {
  .modal.xl-modal {
    .modal-dialog {
      max-width: 700px;
    }
  }
}

.modal.xl-modal-leave {
  animation-name: slideOutToBottom;
  animation-duration: 0.35s;
}

// for mobile/tablet screens when they are landscape
@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  .modal-dialog {
    height: 100%;
    width: 100%;
    max-width: 100% !important;
    margin: 0;
  }
  .modal-content {
    height: 100%;
  }
}

@media (max-width: 575.98px) {
  .modal-backdrop {
    // background: transparent;
  }

  .modal.default-modal,
  .modal.large-modal {
    animation-name: slideInFromBottom;
    animation-duration: 0.35s;

    .modal-dialog {
      margin: 0;
      height: 100%;

      .modal-content {
        border: none;
        border-radius: 0;
        min-height: 100%; // dont think we need this
        transition-property: top;
        transition-timing-function: ease-in-out;
        transition-duration: 0.5s;
        top: 0;

        &.slide-down-animation {
          position: absolute;
          top: calc(100vh - 130px);
          border-radius: 15px;
        }
      }

      .modal-body {
        margin-bottom: 50px;
        background-color: white;
      }

      .modal-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #ffffff;
      }
    }
  }

  .modal.default-modal-leave {
    animation-name: slideOutToBottom;
    animation-duration: 0.35s;
  }

  .modal.large-modal-leave {
    animation-name: slideOutToBottom;
    animation-duration: 0.35s;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideOutToBottom {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

/* Account Details NGB Styles */
.new-yard-modal .modal-dialog {
  width: 60vw;
  max-width: 60vw;
}

// full-page-modal start
.full-page-modal {
  animation-name: slideInFromBottom;
  animation-duration: 0.35s;

  .modal-dialog {
    position: absolute;
    margin: 0;
    width: 100%;
    max-width: 100%;
  }

  .modal-content {
    height: 100vh;
    overflow-y: scroll;
    border: none;
    border-radius: unset;
  }
}

.full-page-modal-leave {
  animation-name: slideOutToBottom;
  animation-duration: 0.35s;
}

.modal-body.fixed-header {
  height: calc(100vh - 73px);
  overflow-y: auto;

  &.fixed-footer {
    height: calc(100vh - 152px);
  }
}

.modal-footer.flat-footer {
  margin: 0;
  padding: 0;
  flex-wrap: nowrap;
  border: none;
  height: 78px;

  > * {
    margin: 0;
  }
}

/* purgecss end ignore */
