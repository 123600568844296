@import "bootstrap-variables";

@font-face {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 300; // book
  src: url("https://company.rubbl.com/pubweb/fonts/CircularStd-Book.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: medium; // 500
  src: url("https://company.rubbl.com/pubweb/fonts/CircularStd-Medium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: bold; // 700
  src: url("https://company.rubbl.com/pubweb/fonts/CircularStd-Bold.woff") format("woff");
  font-display: swap;
}

* {
  font-family: "Circular Std";
  letter-spacing: 0px;
}

.teko {
  font-family: "Teko", sans-serif;
}

h1 {
  font-family: "Teko", sans-serif;
  letter-spacing: 0px;
  font-size: 76px;
  line-height: 68px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 48px !important;
    line-height: 48px !important;
  }
}

h2 {
  font-family: "Teko", sans-serif;
  font-size: 62px;
  letter-spacing: 0px;
  line-height: 54px;

  &.call-out-header {
    font-size: 44px;
    font-weight: 300;
    line-height: 42px;
  }

  &.landing-page {
    font-size: 54px;
  }
}

@include media-breakpoint-down(md) {
  h2 {
    line-height: 50px;
  }
}

// for mobile
@media (max-width: 767.98px) {
  h2 {
    font-size: 48px;
    line-height: 46px;

    &.landing-page {
      font-size: 40px;
      line-height: 50px;
    }
  }
}

.side-headings {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.lite:before,
  &.lite:after {
    opacity: 0.5;
  }
}

.side-headings:before,
.side-headings:after {
  content: "";
  border-top: 1px solid $light-grey;
  margin: 0 30px 0 30px;
  flex: 1 0 20px;
}

.side-headings:after {
  margin: 0 30px 0 30px;
}

@include media-breakpoint-down(md) {
  .side-headings:before,
  .side-headings:after {
    margin: 0 30px 0 0px;
  }

  .side-headings:after {
    margin: 0 0px 0 30px;
  }
}

h3 {
  font-size: 24px;
  font-weight: 500;
}

h4 {
  font-size: 20px;
  font-weight: 500;
}

h4.medium {
  font-weight: 500;
}

.section-header {
  font-weight: 500;
  font-size: 18px;
}

.subhead {
  font-size: 20px;
  font-weight: 500;
}

.subhead-1 {
  font-size: 18px;
  font-weight: medium;
}

.subhead-2 {
  font-weight: 300;
}

.subhead-3 {
  font-size: 13px;
  font-weight: 300;
}

.subhead-4 {
  font-size: 10px;
  font-weight: 500;
}

.paragraph-text {
  font-size: 0.9375rem; // 15px
}

.call-out-text {
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 500;
  color: $text-grey;
}

.secondary-call-out {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
}

.third-call-out {
  text-transform: uppercase;
  letter-spacing: 2.625px;
  font-size: 14px;
  font-weight: bold;
}

.small-call-out {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
}

.supporting-text {
  font-size: 14px;
  font-weight: 300;
}

.supporting-text-bold {
  font-size: 14px;
  font-weight: 500;
}

a {
  transition: all 0.2s ease 0s;
}

a:hover {
  text-decoration: none;
}

small {
  font-size: 0.75rem;
  font-weight: 300;
}

.bold {
  font-weight: bold !important;
}

dl dt,
dd {
  font-size: 0.9375rem;
  font-weight: 300;
}

dl.inverse dt {
  color: $text-grey;
}

.list-group-flush .list-group-item:first-child {
  border-top: none;
}

.list-group-flush .list-group-item:last-child {
  border-bottom: none;
}

.text-grey sup {
  color: $text-grey !important;
}

.icon-large {
  font-size: 24px;
}

.icon-super-large {
  font-size: 50px;
}

.address {
  line-height: 25px;
}

.typewriter-text {
  margin-right: 0.5rem;
}

.big-red-numbers {
  font-size: 140px;
  font-family: "Teko", sans-serif;
  color: $brand-red;
  letter-spacing: -5px;
  font-weight: 300;
  line-height: 85px;
}

.font-weight-500 {
  font-weight: 500;
}
