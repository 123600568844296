/* purgecss start ignore */
@font-face {
  font-family: "icomoon";
  src: url("https://company.rubbl.com/pubweb/marketplace/icomoon/fonts/icomoon.eot?lofgjt");
  src: url("https://company.rubbl.com/pubweb/marketplace/icomoon/fonts/icomoon.eot?lofgjt#iefix") format("embedded-opentype"),
    url("https://company.rubbl.com/pubweb/marketplace/icomoon/fonts/icomoon.ttf?lofgjt") format("truetype"),
    url("https://company.rubbl.com/pubweb/marketplace/icomoon/fonts/icomoon.woff?lofgjt") format("woff"),
    url("https://company.rubbl.com/pubweb/marketplace/icomoon/fonts/icomoon.svg?lofgjt#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user:before {
  content: "\e937";
}
.icon-money:before {
  content: "\e938";
}
.icon-delete:before {
  content: "\e939";
}
.icon-edit:before {
  content: "\e93a";
}
.icon-ach:before {
  content: "\e935";
}
.icon-credit-card:before {
  content: "\e936";
}
.icon-rubbl:before {
  content: "\e934";
}
.icon-crane-1:before {
  content: "\e91c";
}
.icon-crane-2:before {
  content: "\e91d";
}
.icon-crawler-crane-1:before {
  content: "\e91e";
}
.icon-crawler-crane-2:before {
  content: "\e91f";
}
.icon-crawler-dozer:before {
  content: "\e920";
}
.icon-crawler-excavator:before {
  content: "\e921";
}
.icon-crawler-loader:before {
  content: "\e922";
}
.icon-dump-truck:before {
  content: "\e923";
}
.icon-flatbed-truck:before {
  content: "\e924";
}
.icon-fork-truck:before {
  content: "\e925";
}
.icon-forklift:before {
  content: "\e926";
}
.icon-mini-crawler-excavator:before {
  content: "\e927";
}
.icon-screw-pile-tractor:before {
  content: "\e928";
}
.icon-tractor-1:before {
  content: "\e929";
}
.icon-tractor-2:before {
  content: "\e92a";
}
.icon-tractor-3:before {
  content: "\e92b";
}
.icon-truck:before {
  content: "\e92c";
}
.icon-wheel-crane-1:before {
  content: "\e92d";
}
.icon-wheel-crane-2:before {
  content: "\e92e";
}
.icon-wheel-crane-3:before {
  content: "\e92f";
}
.icon-wheel-excavator:before {
  content: "\e930";
}
.icon-wheel-loader-2:before {
  content: "\e931";
}
.icon-wheel-loader:before {
  content: "\e932";
}
.icon-wrecking-ball-crane:before {
  content: "\e933";
}
.icon-map:before {
  content: "\e91b";
}
.icon-mag-glass:before {
  content: "\e91a";
}
.icon-arrow-right:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow:before {
  content: "\e902";
}
.icon-carrot:before {
  content: "\e903";
}
.icon-checkmark:before {
  content: "\e904";
}
.icon-circle-arrow-left:before {
  content: "\e905";
}
.icon-circle-arrow-right:before {
  content: "\e906";
}
.icon-close:before {
  content: "\e907";
}
.icon-crosshair:before {
  content: "\e908";
}
.icon-damage-rec:before {
  content: "\e909";
}
.icon-grid:before {
  content: "\e90a";
}
.icon-insurance:before {
  content: "\e90b";
}
.icon-list:before {
  content: "\e90c";
}
.icon-lock:before {
  content: "\e90d";
}
.icon-long-arrow-right:before {
  content: "\e90e";
}
.icon-notification:before {
  content: "\e90f";
}
.icon-profile:before {
  content: "\e910";
}
.icon-question:before {
  content: "\e911";
}
.icon-save:before {
  content: "\e912";
}
.icon-service:before {
  content: "\e913";
}
.icon-share:before {
  content: "\e914";
}
.icon-shopping-cart:before {
  content: "\e915";
}
.icon-stepper-add:before {
  content: "\e916";
}
.icon-stepper-subtract:before {
  content: "\e917";
}
.icon-transportation:before {
  content: "\e918";
}
.icon-warranty:before {
  content: "\e919";
}
.icon-peapod:before {
  content: "\e93b";
}
.icon-latitude:before {
  content: "\e93c";
}
.icon-longitude:before {
  content: "\e93d";
}
.icon-replacement:before {
  content: "\e93e";
}
.icon-separate:before {
  content: "\e93f";
}
.icon-alert:before {
  content: "\e940";
}
.icon-add:before {
  content: "\e941";
}
.icon-clip:before {
  content: "\e942";
}
.icon-time:before {
  content: "\e943";
}
.icon-date-time:before {
  content: "\e944";
}
.icon-send:before {
  content: "\e945";
}
.icon-hard-hat:before {
  content: "\e946";
}
.icon-house:before {
  content: "\e947";
}
.icon-key:before {
  content: "\e948";
}
.icon-wrench:before {
  content: "\e949";
}
.icon-eyeball:before {
  content: "\e94a";
}
.icon-inspection:before {
  content: "\e94b";
}
.icon-support:before {
  content: "\e94c";
}
.icon-play-button:before {
  content: "\e94d";
}
.icon-facebook:before {
  content: "\e94e";
}
.icon-instagram:before {
  content: "\e94f";
}
.icon-linkedin:before {
  content: "\e950";
}
.icon-youtube:before {
  content: "\e951";
}
.icon-envelope:before {
  content: "\e952";
}
.icon-phone:before {
  content: "\e953";
}
.icon-clipboard-fill:before {
  content: "\e954";
}
.icon-truck-fill:before {
  content: "\e955";
}
.icon-check-circle-fill:before {
  content: "\e956";
}
.icon-clock-fill:before {
  content: "\e957";
}
.icon-clipboard-check-fill:before {
  content: "\e958";
}
.icon-vetted:before {
  content: "\e959";
}
.icon-vizualized:before {
  content: "\e95a";
}
.icon-percentage:before {
  content: "\e95b";
}
.icon-lightning:before {
  content: "\e95c";
}
.icon-import:before {
  content: "\e95d";
}
.icon-services:before {
  content: "\e95e";
}
.icon-earnings:before {
  content: "\e95f";
}
.icon-chat:before {
  content: "\e960";
}
.icon-right:before {
  content: "\e961";
}
.icon-left:before {
  content: "\e962";
}
.icon-transpo:before {
  content: "\e963";
}
.icon-cancel:before {
  content: "\e964";
}
.icon-overages:before {
  content: "\e965";
}
.icon-statement:before {
  content: "\e966";
}
.icon-quote:before {
  content: "\e967";
}
.icon-delivered:before {
  content: "\e968";
}
.icon-switch:before {
  content: "\e969";
}
.icon-tag:before {
  content: "\e96a";
}
.icon-call:before {
  content: "\e96b";
}
.icon-camera:before {
  content: "\e96c";
}
.icon-scan:before {
  content: "\e96d";
}
.icon-twitter:before {
  content: "\e96e";
}
.icon-filter:before {
  content: "\e96f";
}
.icon-money-symbol:before {
  content: "\e970";
}
.icon-listing:before {
  content: "\e971";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-zoom-in:before {
  content: "\e987";
}
.icon-zoom-out:before {
  content: "\e988";
}
.icon-notification1:before {
  content: "\ea08";
}
.icon-blocked:before {
  content: "\ea0e";
}
.icon-play3:before {
  content: "\ea1c";
}
.icon-pause2:before {
  content: "\ea1d";
}
.icon-stop2:before {
  content: "\ea1e";
}
/* purgecss end ignore */
