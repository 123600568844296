$class-border-radius: br !default;

@for $i from 0 through 6 {
  .#{$class-border-radius}-#{$i} {
    border-radius: #{$i}px;
  }
}

$class-font-size: fs !default;

@for $i from 0 through 40 {
  .#{$class-font-size}-#{$i} {
    font-size: #{$i}px !important;
  }
}

.b-grey {
  border: 1px solid $border-grey;
}

.b-light-grey {
  border: 1px solid $light-grey;
}

.bb {
  border-bottom: 1px solid $dark-grey;
}

.bb-light {
  border-bottom: 1px solid $light-grey;
}

.bb-green {
  border-bottom: 1px solid $link-green;
}

.br-light {
  border-right: 1px solid $light-grey;
}

.bl-light {
  border-left: 1px solid $light-grey;
}

.bg-light-grey {
  background: $background-grey;
}

.bt {
  border-top: 1px solid $dark-grey;
}

.bt-light {
  border-top: 1px solid $light-grey;
}

.bb-white {
  border-bottom: 1px solid #fff;
  opacity: 0.3;
}

@media (max-width: 767.98px) {
  .bb-mobile {
    border-bottom: 1px solid $dark-grey;
  }
}

.bb-0 {
  border: 0;
}

.mw-25 {
  max-width: 25%;
}

.mw-50 {
  max-width: 50%;
}

.mb-6 {
  margin-bottom: 6rem;
}
